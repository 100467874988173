<template>
  <el-dialog :title='td("修改转仓单")' :visible="OrderUpdateVisible" @close="onClose" :close-on-click-modal="false" width="700px" append-to-body>
    <el-form
      label-position="top"
      label-width="80px"
      ref="form"
      :model="UpdateList"
      :rules="rules"
	  style="margin-left: 20px;"
    >
      <el-row class="msgConRowf" style="height: 250px;padding-top:8px;margin-left: 20px;">
        <el-col :span="8">
          <el-form-item :label='td("单号")' prop="billNo">
            <el-input
              style="width: 200px"
              v-model="UpdateList.billNo"
              :placeholder='td("单号为自动生成")'
            ></el-input>
          </el-form-item>
		  <el-form-item :label='td("转仓日期")' prop="wareTime">
		    <el-date-picker
		      v-model="UpdateList.inTime"
		      type="datetime"
		      range-separator="至"
		      :start-placeholder='td("开始日期")'
		      :end-placeholder='td("截止日期")'
		      style="width: 200px"
		    >
		    </el-date-picker>
		  </el-form-item>
		  <el-form-item :label='td("备注")'>
		              <el-input
		                type="textarea"
		                style="width: 200px"
		                v-model="UpdateList.remark"
		              ></el-input>
		            </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label='td("源仓库")' prop="sourceStock">
            <el-select
              v-model="UpdateList.sourceStock"
              :placeholder='td("请选择")'
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in WareList"
                :key="index"
                :label="td(item.depotName)"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
		  <el-form-item :label='td("物流公司")' prop="logistics">
		    <!-- <el-input style="width:250px" v-model="UpdateList.logisticsCompany"></el-input> -->
		    <el-select
		      v-model="UpdateList.logisticsCompany"
		      :placeholder='td("请选择物流公司")'
		      style="width: 200px"
		    >
		      <el-option
		        v-for="item in logisticsLists"
		        :key="item.id"
		        :label="item.label"
		        :value="td(item.shortName)"
		      />
		    </el-select>
		  </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label='td("目的库")' prop="destStock">
            <el-select
              v-model="UpdateList.destStock"
              :placeholder='td("请选择")'
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in WareList"
                :key="index"
                :label="td(item.depotName)"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
		  <el-form-item :label='td("快递单号")' prop="courier">
		    <el-input
		      style="width: 200px"
		      v-model="UpdateList.wayBillNo"
		    ></el-input>
		  </el-form-item>
        </el-col>
        <el-col :span="8">
          
        </el-col>

        <el-col :span="8">
          
        </el-col>

        <el-col :span="8">
         
        </el-col>
        <el-col :span="16" style="margin-top: 20px">
          
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="footet">
      <el-button @click="onClose" type="primary" plain>{{td('取 消')}}</el-button>
      <el-button type="primary" @click="comit(UpdateList)"  style="margin-left:10px">{{td('确 定')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getson,
  addAllotorder,
  updateAllotorder,
} from "@/api/warehouse/delivery/index";

export default {
  name: "OrderUpdate",
  props: ["OrderUpdateVisible", "WareList" ,"logisticsLists"],
  data() {
    return {
      select: "",
      // tableData: [],
      form: {},
      rules: {
        depotId: [
          {
            required: true,
            message: this.td("源仓库不能为空"),
            trigger: "blur",
          },
        ],
        destination: [
          {
            required: true,
            message: this.td("目的仓库不能为空"),
            trigger: "blur",
          },
        ],
      },
      UpdateList: {},
    };
  },
  created() {
    // this.getSonlist();
    // this.logisticsList();
  },
  methods: {
    onClose() {
      this.form = {}
      this.$emit("update:OrderUpdateVisible", false);
      this.$emit("select");
    },
    // logisticsList() {
    //   getLogistics().then((res) => {
    //     this.logisticsLists = res.rows;
    //   });
    // },
    uplist(data) {
      this.UpdateList = data;
    },
    // getSonlist() {
    //   getson().then((res) => {
    //     this.tableData = res.rows;
    //   });
    // },
    AddSonlist(data) {
      this.form.allotDetailList = data;
    },
    comit(form) {
      this.$refs["form"].validate((valid) => {
        updateAllotorder(form).then((res) => {
          this.$emit("update:OrderUpdateVisible", false);
          this.$emit("select");
          this.$message.success(this.td("修改成功"));
        })
      })
    },
  },
}
</script>

<style scoped>
<style scoped>
	::v-deep .el-dialog {
		margin: 10vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 5px 0;
		height: 55vh;
		overflow: auto;
	}

	.el-col {
		height: 40px;
		margin-bottom: 20px;
	}

	.msgConRowf {
		height: 250px;
		padding-top: 8px;
	}
</style>
</style>