<template>
  <!-- 主体内容区 -->
  <div>
    <div class="search">
      <el-form
        :model="queryParams"
        ref="query"
        :inline="true"
        label-position="left"
        :style="{ height: scrollerHeight }"
      >
        <div class="box">
          <el-form-item :label="td('单号')" prop="skuType" class="item1">
            <el-input
              :placeholder="td('请输入单号')"
              size="small"
              v-model="queryParams.billNo"
              clearable
            />
          </el-form-item>
          <el-form-item :label="td('状态')" prop="motherName" class="item3">
            <el-select
              v-model="queryParams.state"
              :placeholder="td('请选择状态')"
              size="small"
            >
              <el-option
                v-for="item in tf('ALLOTORDER_STATUS')"
                :key="item.detailId"
                :label="item.langTranslate"
                :value="item.detailId"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="td('源仓库')" prop="motherName" class="item4">
            <el-select
              v-model="queryParams.sourceStock"
              :placeholder="td('请选择源仓库')"
              size="small"
            >
              <el-option
                v-for="item in WareList"
                :key="item.id"
                :label="td(item.depotName)"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="td('目的库')" prop="motherName" class="item5">
            <el-select
              v-model="queryParams.destStock"
              :placeholder="td('请选择目的库')"
              size="small"
            >
              <el-option
                v-for="item in WareList"
                :key="item.id"
                :label="td(item.depotName)"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <br />
          <el-form-item v-show="showSearchs">
            <template>
              <el-form-item
                :label="td('时间范围')"
                prop="motherName"
                class="item2"
              >
                <el-date-picker
                  clearable
                  size="small"
                  v-model="queryParams.inTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  :placeholder="td('选择转仓时间')"
                />
              </el-form-item>
            </template>
          </el-form-item>
        </div>

        <div class="btns">
          <el-link class="btn11" type="primary" @click="toogleExpands"
            ><i :class="icon"></i>{{ td(font) }}</el-link
          >
          <el-button
            class="btn22"
            type="primary"
            size="small"
            @click="getdeptList(queryParams)"
            >{{td("搜索")}}</el-button
          >
          <el-button
            class="btn33"
            size="small"
            type="primary" plain
            @click="reast()"
            >{{td("重置")}}</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="new_button">
      <el-button
        type="primary"
        size="small"
        @click="OrderAddDig = true"
        style="margin-right: 16px"
        >{{ td("新增转仓单") }}</el-button
      >
    </div>

    <el-table
      ref="table"
      :data="listAll"
      tooltip-effect="dark"
      :header-cell-style="{ background: '#F9FCFF', color: '#323233' }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      row-key="allotId"
    >
      <el-table-column :label="td('单号')" prop="billNo">
        <template slot-scope="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="toogleExpand(scope.row)"
          >
            {{ scope.row.billNo }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column :label="td('状态')" >
        <template slot-scope="scope">
          <!-- {{ showStateList(scope.row.state) }} -->
          {{ tfp('ALLOTORDER_STATUS',scope.row.state) }}
        </template>
      </el-table-column>
      <el-table-column :label="td('转仓时间')" prop="inTime"> </el-table-column>
      <el-table-column :label="td('快递公司')" prop="logisticsCompany">
		<template slot-scope="scope">
			{{td(scope.row.logisticsCompany)}}
		</template>
	  </el-table-column>
      <el-table-column :label="td('物流单号')" prop="wayBillNo">
      </el-table-column>
      <el-table-column :label="td('源仓库')" prop="sourceStock">
        <template slot-scope="scope">
          {{td(showDepotList(scope.row.sourceStock))}}
        </template>
      </el-table-column>
      <el-table-column :label="td('目的库')" prop="destStock">
        <template slot-scope="scope">
          {{td(showDepotList(scope.row.destStock))}}
        </template>
      </el-table-column>
      <el-table-column :label="td('创建时间')" prop="createTime">
      </el-table-column>
      <el-table-column
        :label="td('备注')"
        prop="remark"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column :label="td('操作')">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="updateInorder(scope.row)"
            >{{ td("编辑") }}</el-button
          >
          <el-button
            type="text"
            icon="el-icon-delete"
            @click="open(scope.row.allotId)"
            >{{ td("删除") }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template slot-scope="scope">
          <el-table
            :data="allotDetailMap.get(scope.row.allotId)"
            row-key="sonId"
            :key="detailTableKey"
            style="width: 90%; margin: 0 auto"
            border
          >
            <el-table-column
              :label="td('商品名称')"
              align="center"
              prop="goodsName"
            />
            <el-table-column :label="td('SKU')" align="center" prop="sonSku" />
            <el-table-column
              :label="td('入库数量')"
              align="center"
              prop="allotNum"
            />
            <el-table-column
              :label="td('商品图片')"
              align="center"
              prop="goodsImg"
            >
              <template slot-scope="scope">
                <img
                  :src="scope.row.goodsImg"
                  style="width: 50px; height: 50px"
                />
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="td(total)"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getdeptList"
    />

    <!-- 弹窗 -->

    <OrderAdd
      :OrderAddVisible.sync="OrderAddDig"
      :WareList="WareList"
      @select="getdeptList"
      :logisticsLists="logisticsLists"
    />
    <OrderUpdate
      :OrderUpdateVisible.sync="OrderUpdateDig"
      ref="child"
      :WareUpdateList="WareUpdateList"
      :WareList="WareList"
      :logisticsLists="logisticsLists"
      @select="getdeptList"
    />
  </div>
</template>

<script>
import OrderAdd from "./dialog/OrderAdd.vue";
import OrderUpdate from "./dialog/OrderUpdate.vue";

import Cookies from "js-cookie";
import {
  AllList,
  delAllotorder,
  getSonLists,
  getLogistics,
} from "@/api/warehouse/delivery/index";
import { getListtt } from "@/api/warehouse/index.js";
import { getstatus } from "@/api/model/apis.js";

export default {
  components: { OrderAdd, OrderUpdate },
  name: "delivery",
  data() {
    return {
      input: "",
      radio: "",
      radio2: "",
      radio3: "",
      total: 0,
      detailTableKey: false,
      allotDetailMap: new Map(),
      DmanualAddDig: false,
      OrderAddDig: false,
      OrderUpdateDig: false,
      SonUpdateDig: false,
      showSearchs: false,
      font: "更多",
      icon: "el-icon-arrow-down",
      queryParams: {
        billNo: null,
        state: null,
        inTime: null,
        skuInfo: null,
        sourceStock: null,
        destStock: null,
        pageNum: 1,
        pageSize: 10,
      },
      listAll: [],
      // 状态
      stateList: [],
      //仓库
      WareList: [],
      //仓库名字
      depotList: [],
      //主转仓信息
      WareUpdateList: [],
      //子转仓信息
      WareSonUpdateList: [],
      fzList: [],
      logisticsLists: [],
    };
  },
  created() {
    this.getdeptList();
    this.getwareList();
    // this.getState();
    this.logisticsList();
  },
  computed: {
    //显示仓库名字
    showDepotList: function () {
      return function (number) {
        for (let i = 0; i < this.WareList.length; i++) {
          if (number == this.WareList[i].id) {
            return this.WareList[i].depotName;
          }
        }
      };
    },
    //显示状态名字
    // showStateList: function () {
    //   return function (number) {
    //     for (let i = 0; i < this.stateList.length; i++) {
    //       if (number == this.stateList[i].detailId) {
    //         return this.stateList[i].fl;
    //       }
    //     }
    //   };
    // },
    scrollerHeight: function () {
      return this.showSearchs ? 160 + "px" : 80 + "px";
    },
  },
  methods: {
    //点击母商品名，显示子SKU
    toogleExpand(val) {
      let $table = this.$refs.table;
      $table.toggleRowExpansion(val);
    },
    //获取状态常量
    // getState() {
    //   let lang = Cookies.get("langId");
    //   getstatus(lang, "ALLOTORDER_STATUS").then((response) => {
    //     this.stateList = response.data;
    //   });
    // },
    //重置
    reast() {
      this.queryParams = {};
      this.getdeptList();
    },
    //查询转仓单信息
    getdeptList(queryParams) {
      AllList(queryParams).then((res) => {
        this.listAll = res.rows;
        this.total = res.total;
      });
      //子
      getSonLists().then((res) => {
        this.fzList = res.rows;
        for (const e of this.fzList) {
          this.allotDetailMap.set(e.allotId, e.allotDetailList);
        }
      });
    },
    //查询仓库信息
    getwareList() {
      getListtt().then((res) => {
        this.WareList = res.data;
      });
    },
    logisticsList() {
      getLogistics().then((res) => {
        this.logisticsLists = res.rows;
      });
    },
    updateInorder(data) {
      this.$refs.child.uplist(data);
      this.OrderUpdateDig = true;
    },
    //删除
    async open(id) {
      const confirmResult = await this.$confirm(
        this.td("是否删除该仓库?"),
        this.td("提示"),
        {
          confirmButtonText: this.td("确定"),
          cancelButtonText: this.td("取消"),
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm")
        return this.$message.info(this.td("已取消删除"));
      delAllotorder(id).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(this.td("删除失败"));
        }
        this.$message.success(this.td("删除成功"));
        this.getdeptList();
      });
    },
    toogleExpands() {
      if (this.showSearchs) {
        this.showSearchs = !this.showSearchs;
        this.icon = "el-icon-arrow-down";
        this.font = "更多";
      } else {
        this.showSearchs = !this.showSearchs;
        this.font = "收起";
        this.icon = "el-icon-arrow-up";
      }
    },
  },
};
</script>

<style scoped>
.search {
  position: relative;
  background-color: #fff;
}
.el-form {
  margin-top: 24px;
  padding: 0 16px;
  width: 98%;
}
.box {
  position: absolute;
  top: 20px;
  left: 16px;
}
  .search ::v-deep .el-input__inner {
  width: 220px;
}
.search ::v-deep .el-form-item__label {
  margin: 0 24px 0 32px;
  padding: 0;
}
.item1 ::v-deep .el-form-item__label {
  margin: 0 24px 0 0;
}
.item2 ::v-deep .el-form-item__label {
  margin: 0 24px 0 0;
}
.search ::v-deep .el-form--inline,
.el-form-item {
  margin-right: 0;
}

.btns {
  position: absolute;
  top: 25px;
  right: 16px;
}
.btns .el-button {
  width: 80px;
  height: 32px;
}
::v-deep .el-button + .el-button {
  margin-left: 0px;
}
.btns .btn11,
.btn22 {
  margin-right: 16px;
}
.new_button {
  margin-top: 24px;
  width: 100%;
  height: 80px;
  background-color: #fff;
}
.new_button .el-button {
  float: right;
  margin-top: 24px;
}
.el-table {
  padding: 0 16px;
}

/* 110% */
@media screen and (max-width: 1800px) {
  .search ::v-deep .el-input__inner {
  width: 200px;
}
  /* .search ::v-deep .el-form-item__label {
    margin: 0 24px 0 16px;
    padding: 0;
  }
  .item1 ::v-deep .el-form-item__label {
    margin: 0 24px 0 16px;
  }
  .item2 ::v-deep .el-form-item__label {
    margin: 0 24px 0 0;
  } */

  .item1 {
    margin: 0;
    /* transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85); */
  }
  .item2 {
    margin: 0;
    /* transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85); */
  }
  .item3 {
    margin: 0;
    /* transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85); */
  }
  .item4 {
    margin: 0;
    /* transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85); */
  }
  .item5 {
    margin: 0;
    /* transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85); */
  }

  .btns .btn11 {
    margin-right: 16px;
    transform: translate(64px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(64px, 0) scale(0.85, 0.85);
    -moz-transform: translate(64px, 0) scale(0.85, 0.85);
    -o-transform: translate(64px, 0) scale(0.85, 0.85);
  }
  .btns .btn22 {
    margin-right: 16px;
    transform: translate(44px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(44px, 0) scale(0.85, 0.85);
    -moz-transform: translate(44px, 0) scale(0.85, 0.85);
    -o-transform: translate(44px, 0) scale(0.85, 0.85);
  }
  .btns .btn33 {
    margin-right: 16px;
    transform: translate(26px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(26px, 0) scale(0.85, 0.85);
    -moz-transform: translate(26px, 0) scale(0.85, 0.85);
    -o-transform: translate(26px, 0) scale(0.85, 0.85);
  }

  .new_button .el-button {
    transform: translate(16px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(16px, 0) scale(0.85, 0.85);
    -moz-transform: translate(16px, 0) scale(0.85, 0.85);
    -o-transform: translate(16px, 0) scale(0.85, 0.85);
  }
  .el-table {
    font-size: 10px;
  }
}

/* 125% */
@media screen and (max-width: 1620px) {
    .search ::v-deep .el-input__inner {
  width: 160px;
}
  .search ::v-deep .el-form-item__label {
    margin: 0 24px 0 32px;
    padding: 0;
  }
  .item1 ::v-deep .el-form-item__label {
    margin:  0 24px 0 32px;
  }
  .item2 ::v-deep .el-form-item__label {
    margin: 0 24px 0 32px;
  }

  .item1 {

    transform: translate(-30px, 0) scale(0.85, 0.75);
    -webkit-transform: translate(-30px, 0) scale(0.95, 0.95);
    -moz-transform: translate(-30px, 0) scale(0.75, 0.75);
    -o-transform: translate(-30px, 0) scale(0.75, 0.75);
  }
  .item2 {

    transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.95, 0.95);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85);
  }
  .item3 {

    transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.95, 0.95);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85);
  }
  .item4 {

    transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.95, 0.95);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85);
  }
  .item5 {

    transform: translate(-30px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(-30px, 0) scale(0.95, 0.95);
    -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
    -o-transform: translate(-30px, 0) scale(0.85, 0.85);
  }

  .btns .btn11 {
    margin-right: 16px;
    transform: translate(64px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(56px, 0) scale(0.85, 0.85);
    -moz-transform: translate(64px, 0) scale(0.85, 0.85);
    -o-transform: translate(64px, 0) scale(0.85, 0.85);
  }
  .btns .btn22 {
    margin-right: 16px;
    transform: translate(44px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(44px, 0) scale(0.85, 0.85);
    -moz-transform: translate(44px, 0) scale(0.85, 0.85);
    -o-transform: translate(44px, 0) scale(0.85, 0.85);
  }
  .btns .btn33 {
    margin-right: 16px;
    transform: translate(26px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(26px, 0) scale(0.85, 0.85);
    -moz-transform: translate(26px, 0) scale(0.85, 0.85);
    -o-transform: translate(26px, 0) scale(0.85, 0.85);
  }

  .new_button .el-button {
    transform: translate(16px, 0) scale(0.85, 0.85);
    -webkit-transform: translate(16px, 0) scale(0.85, 0.85);
    -moz-transform: translate(16px, 0) scale(0.85, 0.85);
    -o-transform: translate(16px, 0) scale(0.85, 0.85);
  }
  .el-table {
    font-size: 10px;
  }
}

/* 150% */
@media screen and (max-width: 1500px) {
    .search ::v-deep .el-input__inner {
  width: 160px;
}
  .search ::v-deep .el-form-item__label {
    margin: 0 18px 0 0px ;
    padding: 0;
  }
  .item1 ::v-deep .el-form-item__label {
    margin:  0 18px 0 0px;
  }
  .item2 ::v-deep .el-form-item__label {
    margin: 0 18px 0 0px;
  }

  .item1 {
    transform: translate(-30px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(-40px, 0) scale(0.75, 0.75);
    -moz-transform: translate(-30px, 0) scale(0.65, 0.65);
    -o-transform: translate(-30px, 0) scale(0.65, 0.65);
  }
  .item2 {
    transform: translate(200px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(-30px, 0) scale(0.75, 0.75);
    -moz-transform: translate(-30px, 0) scale(0.65, 0.65);
    -o-transform: translate(-30px, 0) scale(0.65, 0.65);
  }
  .item3 {
    transform: translate(-30px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(-30px, 0) scale(0.75, 0.75);
    -moz-transform: translate(-30px, 0) scale(0.65, 0.65);
    -o-transform: translate(-30px, 0) scale(0.65, 0.65);
  }
  .item4 {
    transform: translate(-30px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(-30px, 0) scale(0.75, 0.75);
    -moz-transform: translate(-30px, 0) scale(0.65, 0.65);
    -o-transform: translate(-30px, 0) scale(0.65, 0.65);
  }
  .item5 {
    margin: 0;
    transform: translate(-30px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(-30px, 0) scale(0.75, 0.75);
    -moz-transform: translate(-30px, 0) scale(0.65, 0.65);
    -o-transform: translate(-30px, 0) scale(0.65, 0.65);
  }

  .btns .btn11 {
    margin-right: 16px;
    transform: translate(64px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(90px, 0) scale(0.65, 0.65);
    -moz-transform: translate(64px, 0) scale(0.65, 0.65);
    -o-transform: translate(64px, 0) scale(0.65, 0.65);
  }
  .btns .btn22 {
    margin-right: 16px;
    transform: translate(44px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(62px, 0) scale(0.65, 0.65);
    -moz-transform: translate(44px, 0) scale(0.65, 0.65);
    -o-transform: translate(44px, 0) scale(0.65, 0.65);
  }
  .btns .btn33 {
    margin-right: 16px;
    transform: translate(26px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(26px, 0) scale(0.65, 0.65);
    -moz-transform: translate(26px, 0) scale(0.65, 0.65);
    -o-transform: translate(26px, 0) scale(0.65, 0.65);
  }

  .new_button .el-button {
    transform: translate(16px, 0) scale(0.65, 0.65);
    -webkit-transform: translate(16px, 0) scale(0.65, 0.65);
    -moz-transform: translate(16px, 0) scale(0.65, 0.65);
    -o-transform: translate(16px, 0) scale(0.65, 0.65);
  }
  .el-table {
    font-size: 10px;
  }
}
</style>