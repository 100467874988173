<template>
	<el-dialog :title='td("新增转仓单")' :visible="OrderAddVisible" @close="onClose" :close-on-click-modal="false"
		width="1200px" append-to-body>
		<el-form ref="forms" :rules="rules" :model="form" label-position="top" label-width="80px">
			<el-row style="height: 250px;padding-top:8px;margin-left: 20px;">
				<el-col :span="8">
					<el-form-item :label='td("源仓库")' prop="sourceStock">
						<el-select v-model="form.sourceStock" :placeholder="td('请选择源仓库')" style="width: 96%">
							<el-option v-for="(item, index) in WareList" :key="index" :label="td(item.depotName)"
								:value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="8">
					<el-form-item :label="td('目的库')" prop="destStock">
						<el-select v-model="form.destStock" :placeholder='td("请选择目的库")' style="width: 96%">
							<el-option v-for="(item, index) in WareList" :key="index" :label="td(item.depotName)"
								:value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="8">
					<el-form-item :label='td("转仓日期")' prop="inTime">
						<el-date-picker v-model="form.inTime" type="datetime" :range-separator='td("至")'
							:start-placeholder='td("开始日期")' :placeholder='td("请选择转仓日期")' :end-placeholder='td("截止日期")' style="width: 96%">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("物流公司")' prop="logisticsCompany">
						<!-- <el-input style="width: 250px" v-model="form.logisticsCompany" ></el-input> -->
						<el-select v-model="form.logisticsCompany" :placeholder='td("请选择物流公司")' style="width: 96%">
							<el-option v-for="item in logisticsLists" :key="item.id" :label="item.label"
								:value="td(item.shortName)" />
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="8">
					<el-form-item :label='td("快递单号")' prop="wayBillNo">
						<el-input style="width: 96%" v-model="form.wayBillNo" :placeholder='td("请输入快递单号")'></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label='td("备注")'>
						<el-input type="textarea" :show-word-limit='true' maxlength="200" :rows="5" style="width: 96%"
							v-model="form.remark" :placeholder='td("请输入备注")'></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" style="margin:0 0 20px 20px;">
					<h4>{{td("入库明细")}}</h4>
					<el-button type="primary" icon="el-icon-plus" size="mini" @click="addSonSku"
						style=" margin-right: 10px; ">{{td("新增")}}</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="handleDeleteSonSku">{{td("删除")}}
					</el-button>
				</el-col>
			</el-row>

			<el-table :data="form.allotDetailList" :row-class-name="rowWmsInOrderDetailIndex"
				@selection-change="AddSonlist" tooltip-effect="dark" border
				:header-cell-style="{ background: '#F9FCFF', color: '#323233' }">

				<el-table-column type="selection" width="50" align="center" />
				<el-table-column :label="td('序号')" align="center" prop="index" width="110" />
				<el-table-column label="seller Sku" width="650" align="center">
					<template slot-scope="scope">
						<!-- <el-form-item> -->
						<!-- <el-form-item>  -->
						<el-select v-model="scope.row.goodsName" @change="chanSelect(scope.row)"
							:placeholder='td("请选择seller Sku")' filterable style="width:620px">
							<el-option v-for="(item, index) in tableData" :key="index" :label="item.sellerSku"
								:value="item.goodsName" />
						</el-select>
						<!-- </el-form-item>  -->
					</template>
				</el-table-column>

				<el-table-column :label="td('数量')" align="center">
					<template slot-scope="scope">
						<el-form-item :prop="'allotDetailList.'+ scope.$index +'.allotNum'" :rules="rules.allotNum"
							style="margin-top:20px;">
							<el-input v-model="scope.row.allotNum" :placeholder='td("请输入数量")'/>
						</el-form-item>
					</template>
				</el-table-column>

				<el-table-column prop="remark" :label="td('备注')" align="center">
					<template slot-scope="scope">
						<el-input type="textarea" v-model="scope.row.remark" :placeholder='td("请输入备注")'/>
					</template>
				</el-table-column>
			</el-table>
		</el-form>
		<!-- 表格 -->
		<span slot="footer" class="footet">
			<el-button @click="onClose" type="primary" plain>{{td('取 消')}}</el-button>
			<el-button type="primary" @click="comit" style="margin-left:10px">{{td('确 定')}}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		addAllotorder,
		updateAllotorder
	} from "@/api/warehouse/delivery";
	import {
		getsons
	} from "@/api/warehouse";

	export default {
		name: "OrderAdd",
		props: ["OrderAddVisible", "WareList", "logisticsLists"],
		data() {
			//验证数字的规则
			var checkEmail = (rule, value, cb) => {
				//验证数字的正则表达式
				const regEmail = /^[0-9]*$/
				if (regEmail.test(value)) {
					//合法邮箱
					return cb();
				}
				cb(new Error(this.td("请输入数字")));
			};
			return {
				select: "",
				tableData: [],
				checkedErpSkuSon: [],
				// logisticsLists: [],
				form: {
					state: "10722",
					allotDetailList: [],
				},
			}
		},
		created() {
			this.getSonlist();
			// this.logisticsList()
		},
		//国际化页面刷新不会失效
		computed: {
			rules() {
				return {
					sourceStock: [{
						required: true,
						message: this.td("源仓库不能为空"),
						trigger: "blur"
					}],
					destStock: [{
						required: true,
						message: this.td("目的仓库不能为空"),
						trigger: "blur"
					}],
					logisticsCompany: [{
						required: true,
						message: this.td("物流公司不能为空"),
						trigger: "blur",
					}],
					wayBillNo: [{
						required: true,
						message: this.td("快递单号不能为空"),
						trigger: "blur"
					}, {
						validator: this.checkEmail,
						trigger: "blur"
					}],
					allotNum: [{
						required: true,
						message: this.td("数量不能为空"),
						trigger: "blur"
					}, {
						validator: this.checkEmail,
						trigger: "blur"
					}],
					goodsName: [{
						required: true,
						message: this.td("Sku不能为空"),
						trigger: "blur"
					}],
					inTime: [{
						required: true,
						message: this.td('\'入库时间\'不能为空'),
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			onClose() {
				this.form = {}
				this.form.allotDetailList = []
				this.$emit("update:OrderAddVisible", false);
				this.$refs.forms.clearValidate()
				// this.$emit("select");
			},
			// logisticsList() {
			//   getLogistics().then( res =>{
			//     this.logisticsLists = res.rows
			//   })
			// },
			getSonlist() {
				getsons().then(res => {
					this.tableData = res.rows;
				})
			},
			chanSelect(params) {
				let choosenItem = this.tableData.filter(item => item.goodsName === params.goodsName)[0];
				for (let i = 0; i < this.form.allotDetailList.length; i++) {
					if (choosenItem.sonSku == this.form.allotDetailList[i].sonSku) {
						return (
							this.$message.warning(this.td('请勿重复选择！')), this.form.allotDetailList.splice(this.form
								.allotDetailList.length - 1)
						)
					}
				}
				this.form.allotDetailList[params.index - 1].sonSku = choosenItem.sonSku
			},

			AddSonlist(selection) {
				this.checkedErpSkuSon = selection.map(item => item.index);
			},
			comit() {
				this.$refs.forms.validate(valid => {
					if (valid) {
						addAllotorder(this.form).then(res => {
							if (res.code !== 200) {
								this.$message.danger(this.td("新增失败"));
							}
							this.$message.success(this.td("新增成功"));
							this.$emit("update:OrderAddVisible", false);
							this.$emit("select");
							this.form = {}
							this.form.allotDetailList = []
						})
					}
				})
			},
			addSonSku() {
				let obj = {}
				obj.sonSku = ''
				obj.remark = ''
				obj.allotNum = ''
				obj.goodsName = ''
				this.form.allotDetailList.push(obj)
			},
			rowWmsInOrderDetailIndex({
				row,
				rowIndex
			}) {
				row.index = rowIndex + 1;
			},
			handleDeleteSonSku() {
				if (this.checkedErpSkuSon.length == 0) {
					this.$message.warning(this.td("请先选择要删除的子SKU数据"));
				} else {
					const allotDetailList = this.form.allotDetailList;
					const checkedErpSkuSon = this.checkedErpSkuSon;
					this.form.allotDetailList = allotDetailList.filter(function(item) {
						return checkedErpSkuSon.indexOf(item.index) == -1;
					});
				}
			},
		},
	};
</script>

<style scoped>
	::v-deep .el-dialog {
		margin: 5vh auto !important;
	}

	::v-deep .el-dialog__body {
		padding: 5px 0;
		height: 60vh;
		overflow: auto;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}
</style>
